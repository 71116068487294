<template>
  <div class="bg-purple-950 color-canvas py-8 lg:p-0" data-testid="language-picker-section">
    <SectionWithMaxWidth>
      <div class="flex flex-col">
        <div class="description">
          <RichText :text="blok.description" />
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-7 py-6 lg:mb-12 gap-4">
          <VonageHomePageLanguagePickerToggle
            v-for="language in languages"
            :key="language.uuid"
            v-model="selectedLanguages[language.slug]"
            :language="language.content"
          />
        </div>
      </div>
    </SectionWithMaxWidth>
  </div>
</template>

<script setup lang="ts">
import { type PropType, reactive, watch } from 'vue';
import type { VonageHomePageLanguagePickerSection } from '@/types/storyblok';
import { getResolvedArrayField } from '@/utils/storyblok/resolved';
import VonageHomePageLanguagePickerToggle from '@/components/home/vonage/languages/VonageHomePageLanguagePickerToggle.vue';
import { useLanguagesStore } from '@/store/languages';
import SectionWithMaxWidth from '@/components/utils/SectionWithMaxWidth.vue';
import RichText from '@/components/rich-text/RichText.vue';

const props = defineProps({
  blok: { type: Object as PropType<VonageHomePageLanguagePickerSection>, required: true },
});

const languagesStore = useLanguagesStore();

const languages = getResolvedArrayField(props.blok.languages);

const selectedLanguages = reactive(
  Object.fromEntries(
    languages.map(language => [language.slug, languagesStore.preferredLanguages.includes(language.slug)])
  )
);

watch(
  () => ({ ...selectedLanguages }),
  () => {
    languagesStore.setPreferredLanguages(
      Object.entries(selectedLanguages)
        .filter(([, selected]) => selected)
        .map(([id]) => id)
    );
  }
);
</script>

<style lang="scss" scoped>
.description {
  :deep(h2) {
    margin: 0;
  }
  :deep(p) {
    margin: 4px 0 0 0;
    font-weight: 200;
  }
}
</style>
