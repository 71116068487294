import { defineStore } from 'pinia';
import { type Ref } from 'vue';
import { useCookie } from 'nuxt/app';
import { updatePreferredLanguagesInfo } from '@/utils/analytics/adobe';
import { MaxAgeForCookie } from '@/constants';

export const useLanguagesStore = defineStore('languages', () => {
  const preferredLanguages: Ref<string[]> = useCookie('preferredLanguages', {
    default: () => [],
    maxAge: MaxAgeForCookie,
  });

  // set languages for analytics on load
  updatePreferredLanguagesInfo(preferredLanguages.value);

  const setPreferredLanguages = (value: string[]) => {
    preferredLanguages.value = value;
    updatePreferredLanguagesInfo(preferredLanguages.value);
  };

  return { preferredLanguages, setPreferredLanguages };
});
