<template>
  <div
    data-testid="language-picker-toggle"
    class="flex flex-row p-6 gap-4 items-center shadow-md shadow-[#00000040] rounded cursor-pointer"
    :class="modelValue ? 'bg-purple-600' : 'bg-purple-900 hover:bg-purple-800'"
    @click="onClick"
  >
    <VIcon :name="language.icon" class="text-[40px]" />
    <div class="text-heading-4">{{ language.label }}</div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { CodeLanguage } from '@/types/storyblok';
import { VIcon } from '@vonage/vivid-vue';

const props = defineProps({
  language: { type: Object as PropType<CodeLanguage>, required: true },
  modelValue: { type: Boolean, required: true },
});

const emit = defineEmits(['update:modelValue']);

const onClick = () => {
  emit('update:modelValue', !props.modelValue);
};
</script>
